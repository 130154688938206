import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  PreviewQuestions,
  JWT,
  questionResponse,
  AssessmentInfo,
  SUPPORTED_LANGUAGE_CODES,
  UPUtilityService,
  UPQuestionResponse,
  QuestionLib,
  StudentReportStore,
  questionAccordion,
  Student,
} from 'up';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ltiParams } from 'up/lib/interfaces/config.interface';
import { sectionCreateParams } from 'src/app/shared/interfaces/product.interface';
import { deletedResponse } from 'src/app/shared/interfaces/bank.interface';
import { environment } from 'src/environments/environment';
import {
  AuthorizedUser,
  GenericError,
} from 'up/lib/interfaces/access.interface';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  @Output() submitClickEvent = new EventEmitter<string>();
  @Output() saveExitClickEvent = new EventEmitter<boolean>();
  @Output() toggleQuestionReaderViewEvent = new EventEmitter<boolean>();
  @Output() getModeValue = new EventEmitter<string>();
  @Output() courseAsTemplateEvent = new EventEmitter<boolean>();
  @Output() learnerHeaderNavigationsEvent = new EventEmitter();
  @Output() getPageInfoEvent = new EventEmitter<string>();
  @Output() courseLevelLaunchCloseEvent = new EventEmitter<boolean>();
  ltiParams: ltiParams = {
    userId: 0,
    sectionId: 0,
    isbn: '',
    role: '',
    lmsInstitutionName: '',
    sectionXid: '',
    lmsName: '',
    userXid: '',
    organizationXid: '',
    customLineItemUrl: '',
    resourceLinkId: '',
    goldyAction: '',
    ltiaAction: '',
    isSectionPaired: false,
    platform: '',
    launchId: '',
    ebookId: '',
    attemptId: 0,
    attemptNo: 0,
    assignmentId: 0,
    epubcfi: '',
    assignmentTitle: '',
    isCourseCopy: false,
    mode: '',
    lmsLocale: '',
    isLockedCourse: false,
    isTemplateCourse: false,
    remainingAttempts: 0,
    latestAttemptNo: 0,
    latestAttemptStatus: '',
    maxAttempts: 0,
    hasAssignmentCompleted: false,
    isCourseNavigationLaunch: false,
  };

  learnerQuestionPageTitle: string = '';
  viewAssessmentInfo!: AssessmentInfo;

  timerEditor: any;
  isTokenError: boolean = false;
  showQuestion: boolean = false;
  GO_LANGUAGE: string = 'ENGLISH';

  errorPageTranslationKey!: string;
  EAID!: string;
  questionResponseList: Array<questionResponse> = [];
  welcomeModalFlag: boolean = false;
  apiError: GenericError = {
    status: 0,
    message: '',
  };
  isItemUpdateApiInProgress$ = new BehaviorSubject<boolean>(false);
  studentList: StudentReportStore[] = [];
  constructor(
    private http: HttpClient,
    private title: Title,
    private translate: TranslateService,
    private router: Router,
    private upUtilityService: UPUtilityService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  onSubmitClick(data: string) {
    this.submitClickEvent.emit(data);
  }

  onSaveExitClick() {
    this.saveExitClickEvent.emit(true);
  }
  public deleteLtiParameter(): Observable<deletedResponse> {
    return this.http
      .delete<deletedResponse>(`${environment.apiUrl}/go/params`)
      .pipe(
        tap((data: deletedResponse) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createToken(uniqueKey: string) {
    const reqObject = {
      uniqueKey: uniqueKey,
    };
    return this.http
      .post<JWT>(`${environment.apiUrl}/up/jwt/token/create`, reqObject)
      .pipe(
        tap((res: JWT) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => {
      return error;
    });
  }

  launchGoldy(inputParams: sectionCreateParams): Observable<ltiParams> {
    return this.http
      .post<ltiParams>(`${environment.apiUrl}/go/product/pair`, inputParams)
      .pipe(
        tap((data: ltiParams) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getLmsLocale() {
    return this.ltiParams.lmsLocale || '';
  }

  getReaderLocale() {
    const language = this.getGOLanguage();
    return (SUPPORTED_LANGUAGE_CODES as any)[language].READER;
  }

  getRole() {
    return this.ltiParams.role.toLowerCase();
  }

  getIsCourseCopy() {
    return this.ltiParams.isCourseCopy;
  }

  setIsCourseCopy(isCourseCopy: boolean) {
    this.ltiParams.isCourseCopy = isCourseCopy;
  }

  getLtiaAction() {
    return this.ltiParams.ltiaAction.toLowerCase();
  }

  getSectionPaired() {
    return this.ltiParams.isSectionPaired;
  }

  getAssignmentTitle() {
    return this.ltiParams.assignmentTitle!;
  }

  getISBN() {
    return this.ltiParams.isbn!;
  }

  getUserId() {
    return this.ltiParams.userId;
  }

  getSectionId() {
    return this.ltiParams.sectionId!;
  }

  getEpubcfi() {
    return this.ltiParams.epubcfi;
  }

  getEbookId() {
    return this.ltiParams.ebookId;
  }

  getProductTitle() {
    return this.ltiParams.productTitle!;
  }

  getProductAuthor() {
    return this.ltiParams.productAuthor!;
  }

  getProductEdition() {
    return this.ltiParams.productEdition!;
  }

  getProductRelease() {
    return this.ltiParams.releases!;
  }

  getAssignmentId() {
    return this.ltiParams.assignmentId!;
  }

  getUserXid() {
    return this.ltiParams.userXid;
  }

  getOrganizationXid() {
    return this.ltiParams.organizationXid;
  }

  getPlatformName() {
    return this.ltiParams.platform;
  }

  getLmsName() {
    return this.ltiParams.lmsName;
  }

  setItemInLocalStorage(key: string, val: string) {
    localStorage.setItem(key, val);
    //This code will add the localstorage of the opener window which is the iframe window for instructor
    if (this.getLtiaAction() === 'select')
      window.opener?.localStorage.setItem(key, val);
  }

  setItemInSessionStorage(key: string, val: string) {
    sessionStorage.setItem(key, val);
  }

  getItemFromLocalStorage(key: string) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : '';
  }

  getItemFromSessionStorage(key: string) {
    return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : '';
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }

  removeItemFromLocalStorage(key: string) {
    if (localStorage.getItem(key)) localStorage.removeItem(key);
  }

  setTokenError() {
    this.isTokenError = true;
  }

  getTokenError() {
    return this.isTokenError;
  }

  isValidUser(userAuthorizedData: AuthorizedUser) {
    let validUser = false;
    const role = this.getRole();
    const customAction = this.getLtiaAction();
    switch (userAuthorizedData.authorizedRole) {
      case 'instructor':
        if (role === 'instructor' && customAction === 'select') {
          validUser = true;
        }
        break;
      case 'learner':
        if (
          role === 'learner' ||
          (role === 'instructor' &&
            customAction === 'launch' &&
            !userAuthorizedData.isLearnerOnly)
        ) {
          validUser = true;
        }
        break;
    }
    return validUser;
  }

  toggleQuestionReaderView(showQuestion: boolean) {
    this.showQuestion = showQuestion;
    this.toggleQuestionReaderViewEvent.emit(showQuestion);
  }

  emitAssignmentMode(modeValue: string) {
    this.getModeValue.emit(modeValue);
  }

  setAssessmentDetails(viewAssesmentData: AssessmentInfo) {
    this.viewAssessmentInfo = viewAssesmentData;
  }

  setAssessmentQuestion(questions: PreviewQuestions[]) {
    this.viewAssessmentInfo.assignmentData = questions;
  }

  getAssessmentDetails() {
    return this.viewAssessmentInfo;
  }

  getEAID() {
    return this.EAID;
  }

  setEAID(eaid: string) {
    this.EAID = eaid;
  }

  navigateToErrorPage(translationKey: string) {
    this.errorPageTranslationKey = translationKey;
    this.router.navigateByUrl('/error');
  }

  getErrorPageTranslationKey() {
    return this.errorPageTranslationKey;
  }

  clearErrorPageTranslationKey() {
    this.errorPageTranslationKey = '';
  }

  isGoLoadedInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  storeQuestionResponse(erid: string, renderResponse: UPQuestionResponse) {
    this.questionResponseList.push({ erid: erid, response: renderResponse });
  }

  updateQuestionChoiceOption(erid: string, questionInfo: QuestionLib) {
    this.questionResponseList
      .filter((question: questionResponse) => question.erid === erid)
      .map((question) => {
        question.response.qInfo = JSON.parse(JSON.stringify(questionInfo));
        question.response.isUserResponseSaved = true;
      });
  }

  getQuestionResponse(erid: string) {
    let storedQuestionResponse!: UPQuestionResponse;
    this.questionResponseList.map((question: questionResponse) => {
      if (question.erid === erid) {
        storedQuestionResponse = question.response;
      }
    });
    return storedQuestionResponse;
  }

  getLockedCourse() {
    return this.ltiParams.isLockedCourse;
  }

  getTemplateCourse() {
    return this.ltiParams.isTemplateCourse;
  }

  setCourseAsTemplate(isTemplateCourse: boolean) {
    this.ltiParams.isTemplateCourse = isTemplateCourse;
    this.emitCourseAsTemplateFlag();
  }

  emitCourseAsTemplateFlag() {
    this.courseAsTemplateEvent.emit(this.ltiParams.isTemplateCourse);
  }

  getGOLanguage() {
    return this.GO_LANGUAGE;
  }

  setGOLanguage(language: string) {
    this.GO_LANGUAGE = language;
  }

  setLocale() {
    const lmsLocale = this.getLmsLocale().toLowerCase() || 'en-us';
    console.log('GO locale recieved from LMS:', lmsLocale);

    let GOLanguageCode = '';

    const supportedEnglishCodes = ['en', 'en_us', 'en-us'];
    // canvas = en, BB = en_US, moodle = en_us, d2l = en-US
    const supportedSpanishCodes = ['es', 'es_es', 'es_mx', 'es-mx', 'es-es'];
    // canvas = es, BB = es_ES, moodle = es_mx, d2l = es-MX
    // moodle supports es and es_mx. GO will render es-mx for both these options for now.

    if (supportedEnglishCodes.includes(lmsLocale)) {
      GOLanguageCode = SUPPORTED_LANGUAGE_CODES.ENGLISH.GO;
    } else if (supportedSpanishCodes.includes(lmsLocale)) {
      this.setGOLanguage('SPANISH');
      GOLanguageCode = SUPPORTED_LANGUAGE_CODES.SPANISH.GO;
    }
    this.translate.use(GOLanguageCode);
    // set html lang for screen readers
    this.document.documentElement.lang = GOLanguageCode;
  }

  redrawLearnerNavigations() {
    this.learnerHeaderNavigationsEvent.emit();
  }

  pageInfoEvent(pageInfo: string) {
    this.getPageInfoEvent.emit(pageInfo);
  }

  setWelcomeModalFlag(welcomeModalFlag: boolean) {
    this.welcomeModalFlag = welcomeModalFlag;
  }

  getWelcomeModalFlag() {
    return this.welcomeModalFlag;
  }

  setApiError(message: string, status: number) {
    this.apiError.status = status;
    this.apiError.message = message;
  }
  getApiError() {
    return this.apiError;
  }
  clearApiError() {
    this.apiError.status = 0;
    this.apiError.message = '';
  }
  // getItemUpdateApiStatus() {
  //   return this.isItemUpdateApiInProgress$;
  // }

  setItemUpdateApiStatus(apiStatus: boolean) {
    this.isItemUpdateApiInProgress$.next(apiStatus);
  }

  getCourseLaunchLevel() {
    return this.ltiParams.isCourseNavigationLaunch;
  }

  setLtiParams(ltiResponse: ltiParams) {
    this.ltiParams = ltiResponse;
  }

  getLtiParams(): Observable<ltiParams> {
    return this.http
      .post<ltiParams>(`${environment.apiUrl}/go/params`, {})
      .pipe(
        tap((ltiReponse: ltiParams) => {
          this.setLtiParams(ltiReponse);
          return ltiReponse;
        }),
        catchError(this.handleError)
      );
  }

  getQuestionPageTitle() {
    return this.learnerQuestionPageTitle;
  }

  setQuestionPageTitle(learnerQuestionPageTitle: string) {
    this.learnerQuestionPageTitle = learnerQuestionPageTitle;
  }

  onExit() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/go`);
  }

  saveStudentSearchList(student: StudentReportStore) {
    const studentListExistInChapter = this.studentList.filter(
      (student) => student.chapterId === student.chapterId
    );

    studentListExistInChapter.length > 0
      ? studentListExistInChapter[0].studentList.push(student.studentList[0])
      : this.studentList.push(student);
  }

  getStudentSearchList() {
    return this.studentList;
  }

  clearStudentSearchList(chapterId: number) {
    this.studentList = this.studentList.filter(
      (student) => student.chapterId !== chapterId
    );
  }

  setStudentQuestionList(
    chapterId: number,
    userxid: string,
    questionList: questionAccordion[]
  ) {
    const student = this.studentList.find(
      (student) => student.chapterId === Number(chapterId)
    );
    const questionDetailStudent = student?.studentList[0]?.students.find(
      (student) => student.userxid === userxid
    );

    if (questionDetailStudent) {
      questionDetailStudent.questionDetails = questionList;
    }
  }

  getStudentQuestionList(chapterId: number, userxid: string) {
    let studentDetails = this.studentList.find(
      (student) => student.chapterId === Number(chapterId)
    );
    let student: Student | undefined =
      studentDetails?.studentList[0].students.find(
        (student) => student.userxid === userxid
      );
    return student?.questionDetails;
  }
}
